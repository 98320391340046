<template>
  <div>
    <ProjectDetail />
  </div>
</template>

<script>
import ProjectDetail from '@/components/pages/ProjectDetail.vue';

export default {
  name: 'ProjectPage',
  components: {
    ProjectDetail
  }
};
</script>

<style>
/* 添加你的样式 */
</style>
